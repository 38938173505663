import React, { useState } from "react";

import { Box, Button, Link, Typography, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import AnimalSelection from "./AnimalSelection";
import Loading from "./Loading";

import axios from "axios";
import dayjs from "dayjs";
import BackButton from "./BackButton";

export default function HuntingDay(props) {
  const { back, territory } = props;

  const [content, setContent] = useState({});
  const [huntDay, setHuntDay] = useState(null);
  const [remark, setRemark] = useState("");
  const [responsable, setResponsable] = useState("");

  const [loading, setLoading] = useState(false);

  const submit = () => {
    setLoading(true);
    const data = {
      form: "HuntingDay",
      territory: territory,
      animals: content,
      huntDay: huntDay,
      remark: remark,
      responsable: responsable,
    };
    axios.post("/mobile/api/submit_form.php", data).then(() => {
      setLoading(false);
      back();
    });
  };

  return (
    <Box>
      <Loading loading={loading} />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography variant="h5">
          GTB Gibier tiré lors d'une journée de battue
        </Typography>
        <BackButton back={back} />
        <TextField
          fullWidth
          disabled
          label="Territoire"
          value={territory["name"]}
          margin="normal"
        />
        <DatePicker
          label="Date de battue"
          value={huntDay}
          onChange={(newValue) => {
            setHuntDay(newValue);
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
          minDate={dayjs().subtract(18, "month")}
          maxDate={dayjs()}
          fullWidth
        />

        <TextField
          fullWidth
          label="Responsable données (nom+n° téléphone)"
          value={responsable}
          onInput={(e) => {
            setResponsable(e.target.value);
          }}
        />
        <AnimalSelection content={content} setContent={setContent} />
        <TextField
          fullWidth
          label="Remarques"
          multiline
          value={remark}
          onInput={(e) => {
            setRemark(e.target.value);
          }}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={submit}
        >
          Envoyer
        </Button>
      </LocalizationProvider>
    </Box>
  );
}
