import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slider,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

function PercentSelect(props) {
  const { surfaceId, rowId, data, setData } = props;

  if (!data) {
    return <></>;
  }

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(data["battue" + (rowId + 1)][surfaceId]);

  function handleClose() {
    setOpen(false);
  }

  function update() {
    data["battue" + (rowId + 1)][surfaceId] = value;
    setData({ ...data });
    setOpen(false);
  }

  function openDialog() {
    if (value === "") {
      setValue(100);
    }
    setOpen(true);
  }

  const marks = [
    {
      value: 0,
      label: "0 %",
    },
    {
      value: 50,
      label: "50 %",
    },
    {
      value: 100,
      label: "100 %",
    },
  ];

  let displayValue = value;
  // if (displayValue !== "") {
  //   displayValue += " %";
  // }

  return (
    <React.Fragment>
      <Dialog onClose={update} open={open}>
        <DialogTitle>Select Percent</DialogTitle>
        <DialogContent>
          <Box sx={{ width: 300 }}>
            <br />
            <br />
            <Slider
              value={value}
              aria-label="Default"
              onChange={(e) => setValue(e.target.value)}
              valueLabelDisplay="on"
              marks={marks}
            />
          </Box>
          <Button onClick={update}>Confirmer</Button>
        </DialogContent>
      </Dialog>
      <TextField
        value={displayValue}
        onClick={openDialog}
        sx={{
          "& .MuiInputBase-input": { padding: "4px 12px" },
        }}
      />
    </React.Fragment>
  );
}

function SurfaceArea(props) {
  const { rowId, surfaces, data } = props;
  let area = 0;

  for (let s of surfaces) {
    let a = data["battue" + (rowId + 1)][s["id"]];
    if (a === "") {
      a = 0;
    }
    area += (parseFloat(s["surface"], 10) * parseFloat(a, 10)) / 100;
  }

  return <TableCell>{parseInt(area, 10)} ha</TableCell>;
}

function upTo(n) {
  let result = [];
  for (let j = 0; j < n; j++) {
    result.push(j);
  }
  return result;
}

export default function SurfacePercent(props) {
  const { surfaces, surfaceNumber, data, setData } = props;
  const [rowCount, setRowCount] = useState(surfaceNumber);

  function addRow() {
    setRowCount(rowCount + 1);
    for (let s of surfaces) {
      data[s["id"]][rowCount] = 0;
    }
    setData({ ...data });
  }

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Liste des enceintes (superficie)</TableCell>
            {upTo(rowCount).map((j) => {
              return <TableCell>Battue {j + 1}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {surfaces.map((s) => {
            return (
              <TableRow>
                <TableCell>
                  {s["name"]}
                  <br />({s["surface"]} ha)
                </TableCell>
                {upTo(rowCount).map((j) => {
                  return (
                    <TableCell>
                      <PercentSelect
                        surfaceId={s["id"]}
                        rowId={j}
                        data={data}
                        setData={setData}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}

          <TableRow>
            <TableCell>Calcul de la superficie</TableCell>
            {upTo(rowCount).map((j) => {
              return <SurfaceArea rowId={j} data={data} surfaces={surfaces} />;
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
