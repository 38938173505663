import React, { useState, useEffect } from "react";

import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loading from "./Loading";

import axios from "axios";
import dayjs from "dayjs";
import BackButton from "./BackButton";

function MyTableRow(props) {
  const { row } = props;
  const huntStart = dayjs(row["huntStart"]);
  const huntEnd = dayjs(row["huntEnd"]);
  const date = dayjs(row["date"]);

  return (
    <TableRow key={row["id"]}>
      <TableCell>{row["id"]}</TableCell>
      <TableCell>{date.format("DD.MM.YYYY")}</TableCell>
      <TableCell>{huntStart.format("DD.MM.YYYY")}</TableCell>
      <TableCell>{huntEnd.format("DD.MM.YYYY")}</TableCell>
      <TableCell>{row["formType"]}</TableCell>
      <TableCell>{row["territory"]}</TableCell>
      <TableCell>
        <DeleteIcon
          onClick={() => {
            props.delete(row);
          }}
        />
      </TableCell>
    </TableRow>
  );
}

export default function Summary(props) {
  const { back } = props;
  const [loading, setLoading] = useState(true);

  const [dummy, setDummy] = useState(0);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    axios.get("/mobile/api/submitted_forms.php").then((response) => {
      setForms(response["data"]);
      setLoading(false);
    });
  }, [dummy]);

  const deleteCall = (row) => {
    setLoading(true);
    axios
      .post("/mobile/api/delete_form.php", {
        id: row["id"],
        type: row["formType"],
        territory_id: row["territoryId"],
      })
      .then(() => {
        setDummy(dummy + 1);
        setLoading(false);
      })
      .catch(() => {
        setDummy(dummy + 1);
        setLoading(false);
      });
  };

  return (
    <Box>
      <Loading loading={loading} />
      <Typography variant="h5">
        Liste de formulaires complétés les derniers 8 jours
      </Typography>
      <BackButton back={back} />

      <TableContainer>
        <Table>
          <TableBody>
            {forms.map((row) => {
              return <MyTableRow row={row} delete={deleteCall} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
