import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import Loading from "./Loading";

import axios from "axios";
import dayjs from "dayjs";
import SurfacePercent from "./SurfacePercent";
import BackButton from "./BackButton";
import AnimalObservation from "./AnimalObservation";

export default function Observation(props) {
  const { back, territory } = props;

  const [surfaces, setSurfaces] = useState([]);

  const [huntDay, setHuntDay] = useState(null);
  const [huntNumber, setHuntNumber] = useState(1);
  const [responsable, setResponsable] = useState("");
  const [filesDistributed, setFilesDistributed] = useState(0);
  const [filesHanded, setFilesHanded] = useState(0);
  const [seriesNumber, setSeriesNumber] = useState("");
  const [remark, setRemark] = useState("");

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true);
  const [step, setStep2] = useState(0);

  const [dummy, setDummy] = useState(0);

  const setStep = (step) => {
    window.scrollTo(-50, 0);
    setStep2(step);
  };

  useEffect(() => {
    axios
      .post("/mobile/api/territory_surfaces.php", {
        territory_id: territory["id"],
      })
      .then((response) => {
        setSurfaces(response["data"]);
        setLoading(false);
      });
  }, [dummy]);

  const submit = () => {
    setLoading(true);
    data["territory"] = territory;
    data["huntDay"] = huntDay;
    data["huntNumber"] = huntNumber;
    data["responsable"] = responsable;
    data["filesDistributed"] = filesDistributed;
    data["filesHanded"] = filesHanded;
    data["seriesNumber2"] = seriesNumber;
    data["remark"] = remark;

    axios
      .post("/mobile/api/submit_observation.php", data)
      .then(() => {
        setLoading(false);
        back();
      })
      .catch(() => {
        setLoading(false);
        back("Erreur!!!");
      });
  };

  const goToStep1 = () => {
    for (let j = 0; j < huntNumber; j++) {
      data["battue" + (j + 1)] = {};
      for (let s of surfaces) {
        data["battue" + (j + 1)][s["id"]] = "";
      }
      for (let a of [
        "Boisé",
        "Biche",
        "Faon",
        "Cervidé non-identifié",
        "Brocard",
        "Chevrette",
        "Chevreuil non-identifié",
        "Sanglier",
        "Mouflon",
        "Renard",
      ]) {
        data["battue" + (j + 1)][a] = 0;
      }
    }
    setData({ ...data });
    setStep(1);
  };

  if (step === 0) {
    return (
      <Box>
        <Loading loading={loading} />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography variant="h5">
            SOB Synthèse observation en battue
          </Typography>
          <BackButton back={back} />

          <TextField
            fullWidth
            disabled
            label="Territoire"
            value={territory["name"]}
            margin="normal"
          />
          <DatePicker
            label="Date de battue"
            value={huntDay}
            onChange={(newValue) => {
              setHuntDay(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            minDate={dayjs().subtract(18, "month")}
            maxDate={dayjs()}
            fullWidth
          />

          <TextField
            fullWidth
            label="Responsable données (nom+n° téléphone)"
            value={responsable}
            onInput={(e) => {
              setResponsable(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Nombre de fiches distribués"
            value={filesDistributed}
            onInput={(e) => {
              setFilesDistributed(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Nombre de fiches rendus"
            value={filesHanded}
            onInput={(e) => {
              setFilesHanded(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Numéro de serie"
            value={seriesNumber}
            onInput={(e) => {
              setSeriesNumber(e.target.value);
            }}
          />
          <TextField
            fullWidth
            label="Remarques"
            multiline
            value={remark}
            onInput={(e) => {
              setRemark(e.target.value);
            }}
          />
          <FormControl fullWidth>
            <InputLabel id="hunt-number-select-label">
              Nombre de Battue
            </InputLabel>
            <Select
              labelId="hunt-number-select-label"
              id="hunt-number-select"
              label="Nombre de Battue"
              value={huntNumber}
              onChange={(e) => setHuntNumber(e.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={goToStep1}
          >
            Continuer
          </Button>
        </LocalizationProvider>
      </Box>
    );
  }

  if (step === 1) {
    return (
      <Box>
        <Loading loading={loading} />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Typography variant="h5">
            SOB Synthèse observation en battue ({territory["name"]})
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setStep(0);
            }}
          >
            Précédent
          </Button>
          Veuillez indiquer ci-dessous les enceintes chassées lors de chaque
          battue de la journée. Par battue plusieurs enceintes peuvent êtres
          sélectionnées. Pour les enceintes chassées partiellements il est
          impératif d'indiquer au plus correct possible la % de la superficie
          totale chassé (5 à 100%).
          <SurfacePercent
            surfaces={surfaces}
            surfaceNumber={huntNumber}
            data={data}
            setData={setData}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={() => {
              setStep(2);
            }}
          >
            Continuer
          </Button>
        </LocalizationProvider>
      </Box>
    );
  }
  return (
    <Box>
      <Loading loading={loading} />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography variant="h5">
          SOB Synthèse observation en battue ({territory["name"]})
        </Typography>
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={() => {
            setStep(1);
          }}
        >
          Précédent
        </Button>
        Complètez le formulaire ci-dessous avec les animaux observés lors de
        chaque battue.
        <AnimalObservation
          huntNumber={huntNumber}
          data={data}
          setData={setData}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={submit}
        >
          Envoyer
        </Button>
      </LocalizationProvider>
    </Box>
  );
}
