import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import LogoutIcon from "@mui/icons-material/Logout";

import axios from "axios";

function Message(props) {
  if (!props.message || props.message == "") {
    return <></>;
  }

  return <Alert severity="error">{props.message}</Alert>;
}

export default function SelectForm(props) {
  const [territories, setTerritories] = useState([
    { id: "load", name: "Choissir" },
  ]);
  const [territory, setTerritory] = useState("load");
  const [dummy, setDummy] = useState();
  const [message, setMessage] = useState(props["message"]);

  useEffect(() => {
    axios.get("/mobile/api/territories.php").then((response) => {
      setTerritories(
        [{ id: "load", name: "Choissir" }].concat(response["data"])
      );
    });
  }, [dummy]);

  const getTerritory = () => {
    for (const t of territories) {
      if (t["id"] === territory) {
        return t;
      }
    }
  };

  const done = (form) => {
    if (territory === "load") {
      setMessage("Veuillez sélectionner un territoire svp");
    } else {
      props.done(getTerritory(), form);
    }
  };

  const selectTerritory = (e) => {
    setTerritory(e.target.value);
    setMessage("");
  };

  const logout = () => {
    fetch("/mobile/api/logout.php").then(() => {
      window.location.reload();
    });
  };

  return (
    <Box>
      <Typography variant="h5">
        Conseil Faunistique de la Croix Scaille
      </Typography>
      <List dense={true}>
        <ListItem>
          <ListItemButton
            onClick={() => {
              props.done(null, "Summary");
            }}
          >
            <ListItemIcon>
              <FormatListBulletedIcon />
            </ListItemIcon>
            <ListItemText primary="Liste" />
          </ListItemButton>
        </ListItem>
        <ListItem>
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Terminer la session" />
          </ListItemButton>
        </ListItem>
      </List>

      <Typography variant="h5">Sélectionnez le formulaire</Typography>
      <Message message={message} />
      <FormControl fullWidth>
        <InputLabel id="territoire-select-label">
          Territoire de chasse
        </InputLabel>
        <Select
          labelId="territoire-select-label"
          id="territoire-select"
          value={territory}
          label="Territoire"
          onChange={selectTerritory}
        >
          {territories.map((t) => {
            return (
              <MenuItem value={t["id"]} key={t["id"]}>
                {t["name"]}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => done("A")}
      >
        GTA Gibier tiré lors d'une période de chasse à l'approche et à l'affût
      </Button>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => done("B")}
      >
        GTB Gibier tiré lors d’une journée de battue
      </Button>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => done("Res")}
      >
        GTRES - Résumé gibier tiré période déterminée
      </Button>
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        onClick={() => done("SOB")}
      >
        Synthèse observation en battue
      </Button>
    </Box>
  );
}
