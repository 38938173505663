import React from "react";
import { Box, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Loading(props) {
  const { loading } = props;

  return (
    <Modal open={loading}>
      <Box sx={style}>
        <Typography variant="h3">Veuillez patienter</Typography>
      </Box>
    </Modal>
  );
}
