import React from "react";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function BackButton(props) {
  const { back } = props;
  return (
    <List dense={true}>
      <ListItem>
        <ListItemButton onClick={back}>
          <ListItemIcon>
            <ArrowBackIcon />
          </ListItemIcon>
          <ListItemText primary="Précédent" />
        </ListItemButton>
      </ListItem>
    </List>
  );
}
