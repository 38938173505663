import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function upTo(n) {
  let result = [];
  for (let j = 0; j < n; j++) {
    result.push(j);
  }
  return result;
}

export default function AnimalObservation(props) {
  const { huntNumber, data, setData } = props;
  return (
    <React.Fragment>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Animal</TableCell>
              {upTo(huntNumber).map((j) => {
                return <TableCell>Battue {j + 1}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              "Boisé",
              "Biche",
              "Faon",
              "Cervidé non-identifié",
              "Brocard",
              "Chevrette",
              "Chevreuil non-identifié",
              "Sanglier",
              "Mouflon",
              "Renard",
            ].map((name) => {
              return (
                <TableRow>
                  <TableCell>{name}</TableCell>
                  {upTo(huntNumber).map((x) => {
                    return (
                      <TableCell>
                        <TextField
                          sx={{
                            width: 70,
                            "& .MuiInputBase-input": { padding: "4px 12px" },
                          }}
                          type="number"
                          value={data["battue" + (x + 1)][name]}
                          onChange={(e) => {
                            data["battue" + (x + 1)][name] = e.target.value;
                            setData({ ...data });
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <List dense={true}>
        <ListItem>
          <ListItemButton
            onClick={() => {
              window.scrollTo({
                top: 100,
                left: 0,
                behavior: "smooth",
              });
            }}
          >
            <ListItemIcon>
              <ArrowUpwardIcon />
            </ListItemIcon>
            <ListItemText primary="Début" />
          </ListItemButton>
        </ListItem>
      </List>
    </React.Fragment>
  );
}
