import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import axios from "axios";

import Loading from "./Loading";

function Message(props) {
  if (props.message == "") {
    return <></>;
  }

  return <Alert severity="error">{props.message}</Alert>;
}

function Info(props) {
  if (props.message == "") {
    return <></>;
  }

  return <Alert severity="info">{props.message}</Alert>;
}

export default function LoginForm(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = () => {
    setLoading(true);
    axios
      .post(
        "/mobile/api/login.php",
        {
          username: username,
          password: password,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((result) => {
        console.log(result);
        if (result.data == "success") {
          props.success();
        } else {
          setMessage("Erreur : nom d'utilisateur ou mot de passe incorrect!");
        }
        setLoading(false);
      })
      .catch(() => {
        setMessage("Erreur!!!");
        setLoading(false);
      });
  };

  const handleRecover = () => {
    setLoading(true);
    axios
      .post(
        "/mobile/api/recover.php",
        {
          email: email,
        },
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((result) => {
        console.log(result);
        if (result.data == "success") {
          setInfo("Email est envoyé");
        } else {
          setMessage("Erreur!");
        }
        setLoading(false);
      })
      .catch(() => {
        setMessage("Erreur!!!");
        setLoading(false);
      });
  };

  return (
    <Box component="form" sx={{ margin: 1 }}>
      <Loading loading={loading} />
      <Typography variant="h3">
        Conseil Faunistique de la Croix Scaille
      </Typography>
      <Message message={message} />
      <Info message={info} />
      <div>
        <TextField
          variant="outlined"
          label="Nom d'utilisateur"
          margin="normal"
          fullWidth
          value={username}
          onInput={(e) => setUsername(e.target.value)}
        />
        <TextField
          variant="outlined"
          label="Mot de passe"
          margin="normal"
          fullWidth
          type="password"
          value={password}
          onInput={(e) => setPassword(e.target.value)}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleLogin}
        >
          Continuer
        </Button>
        <br />

        <TextField
          variant="standard"
          label="Email"
          margin="normal"
          fullWidth
          type="email"
          value={email}
          onInput={(e) => setEmail(e.target.value)}
        />
        <Button
          fullWidth
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
          onClick={handleRecover}
        >
          Récupérer le mot de passe
        </Button>
      </div>
    </Box>
  );
}
