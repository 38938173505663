import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { CssBaseline, Container } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import LoginForm from "./components/LoginForm";
import SelectForm from "./components/SelectForm";
import HuntingDay from "./components/HuntingDay";
import HuntingPeriod from "./components/HuntingPeriod";
import HuntingSummary from "./components/HuntingSummary";
import Summary from "./components/Summary";
import Observation from "./components/Observation";

import axios from "axios";

const theme = createTheme();

function App() {
  // const [loggedIn, setLoggedIn] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [form, setForm] = useState(null);
  const [territory, setTerritory] = useState();
  const [message, setMessage] = useState();

  const loginSuccess = () => {
    setLoggedIn(true);
  };

  const selectForm = (territoryInformation, formName) => {
    setForm(formName);
    setTerritory(territoryInformation);
  };

  const backToMainPage = (msg) => {
    if (msg === undefined) {
      msg = "";
    }
    if (msg != "") {
      setMessage(msg);
    }
    setForm("");
  };

  useEffect(() => {
    axios.get("/mobile/api/logged_in.php").then((result) => {
      console.log(result);
      if (result.data === "yes") {
        setLoggedIn(true);
      }
    });
  }, [loggedIn]);

  if (!loggedIn) {
    return (
      <ThemeProvider theme={theme}>
        <Container sx={{ maxWidth: 800 }}>
          <CssBaseline />
          <LoginForm success={loginSuccess} />
        </Container>
      </ThemeProvider>
    );
  }

  if (form === "A") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ maxWidth: 800 }}>
          <HuntingPeriod back={backToMainPage} territory={territory} />
        </Container>
      </ThemeProvider>
    );
  }

  if (form === "B") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ maxWidth: 800 }}>
          <HuntingDay back={backToMainPage} territory={territory} />
        </Container>
      </ThemeProvider>
    );
  }

  if (form === "Res") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ maxWidth: 800 }}>
          <HuntingSummary back={backToMainPage} territory={territory} />
        </Container>
      </ThemeProvider>
    );
  }

  if (form === "SOB") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ maxWidth: 800 }}>
          <Observation back={backToMainPage} territory={territory} />
        </Container>
      </ThemeProvider>
    );
  }

  if (form === "Summary") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container sx={{ maxWidth: 800 }}>
          <Summary back={backToMainPage} />
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container sx={{ maxWidth: 800 }}>
        <SelectForm done={selectForm} message={message} />
      </Container>
    </ThemeProvider>
  );
}

createRoot(document.getElementById("root")).render(<App />);
