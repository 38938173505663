import React, { useState } from "react";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Typography,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import AnimalSelection from "./AnimalSelection";
import Loading from "./Loading";
import BackButton from "./BackButton";

import axios from "axios";
import dayjs from "dayjs";

export default function HuntingSummary(props) {
  const { back, territory } = props;

  const [content, setContent] = useState({});
  const [huntStart, setHuntStart] = useState(null);
  const [huntEnd, setHuntEnd] = useState(null);
  const [dateError, setDateError] = useState("");

  const [remark, setRemark] = useState("");
  const [responsable, setResponsable] = useState("");

  const [loading, setLoading] = useState(false);

  const submit = () => {
    setLoading(true);
    const data = {
      form: "HuntingSummary",
      territory: territory,
      animals: content,
      huntStart: huntStart,
      hundEnd: huntEnd,
      remark: remark,
      responsable: responsable,
    };
    axios.post("/mobile/api/submit_form.php", data).then(() => {
      setLoading(false);
      back();
    });
  };

  return (
    <Box>
      <Loading loading={loading} />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography variant="h5">
          GTRES - Résumé gibier tiré période déterminée
        </Typography>
        <BackButton back={back} />

        <FormControl fullWidth>
          <TextField
            fullWidth
            disabled
            label="Territoire"
            value={territory["name"]}
            margin="normal"
          />

          <DatePicker
            label="Date début"
            value={huntStart}
            onChange={(newValue) => {
              setHuntStart(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth />}
            minDate={dayjs().subtract(18, "month")}
            maxDate={dayjs()}
            fullWidth
          />

          <DatePicker
            label="Date fin"
            value={huntEnd}
            onChange={(newValue) => {
              setHuntEnd(newValue);

              if (newValue < huntStart) {
                setDateError(
                  "La date fin doit être plus tard que la date début"
                );
              } else {
                setDateError("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                error={dateError !== ""}
                helperText={dateError}
              />
            )}
            minDate={dayjs().subtract(18, "month")}
            maxDate={dayjs()}
            fullWidth
          />
        </FormControl>
        <TextField
          fullWidth
          label="Responsable données (nom+n° téléphone)"
          value={responsable}
          onInput={(e) => {
            setResponsable(e.target.value);
          }}
        />
        <AnimalSelection content={content} setContent={setContent} />
        <TextField
          fullWidth
          label="Remarques"
          multiline
          value={remark}
          onInput={(e) => {
            setRemark(e.target.value);
          }}
        />
        <Button
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={submit}
        >
          Envoyer
        </Button>
      </LocalizationProvider>
    </Box>
  );
}
