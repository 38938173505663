import React, { useState } from "react";

import {
  Typography,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function AnimalNumber(props) {
  const { data } = props;
  if (!data) {
    return <span></span>;
  }

  return <span style={{ paddingLeft: 10 }}>({data["number"]})</span>;
}

function AnimalItem(props) {
  const {
    content,
    name,
    errorList,
    updateContentNumber,
    updateContentBracelets,
  } = props;
  return (
    <Accordion key={name}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {name}
        <AnimalNumber data={content[name]} />
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          error={errorList[name] !== ""}
          fullWidth
          label="Nombre"
          type="number"
          onChange={(e) => updateContentNumber(name, e.target.value)}
          helperText={errorList[name]}
        />
        <TextField
          fullWidth
          label="Numéro(s) du/des bracelets"
          onInput={(e) => updateContentBracelets(name, e.target.value)}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default function AnimalSelection(props) {
  const { content, setContent } = props;
  const [errorList, setErrors] = useState({
    "Faon male": "",
    "Faon femelle": "",
    "Biche / Bichette": "",
    "Petit cerf": "",
    "Grand cerf": "",
    Chevrillard: "",
    Chevrette: "",
    Brocard: "",
    "Sanglier max 35 kg": "",
    "Sanglier male > 35 kg": "",
    "Sanglier femelle > 35 kg": "",
    "Sanglier indéterminé": "",
    Mouflon: "",
    Daim: "",
    Renard: "",
    "Raton laveur": "",
  });

  const updateContentNumber = (name, number) => {
    if (!content[name]) {
      content[name] = { name: name, number: 0, bracelets: "" };
    }
    if (parseInt(number, 10) < 0) {
      errorList[name] = "Le nombre doit être positif";
      setErrors(errorList);
    }

    content[name]["number"] = number;
    setContent({ ...content });
  };
  const updateContentBracelets = (name, bracelets) => {
    if (!content[name]) {
      content[name] = { name: name, number: 0, bracelets: "" };
    }

    content[name]["bracelets"] = bracelets;
    setContent({ ...content });
  };

  return (
    <React.Fragment>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Cervides</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {[
            "Faon male",
            "Faon femelle",
            "Biche / Bichette",
            "Grand cerf",
            "Petit cerf",
          ].map((name) => (
            <AnimalItem
              content={content}
              name={name}
              key={name}
              errorList={errorList}
              updateContentBracelets={updateContentBracelets}
              updateContentNumber={updateContentNumber}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Chevreuil</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {["Brocard", "Chevrette", "Chevrillard"].map((name) => (
            <AnimalItem
              content={content}
              name={name}
              key={name}
              errorList={errorList}
              updateContentBracelets={updateContentBracelets}
              updateContentNumber={updateContentNumber}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Sanglier</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {[
            "Sanglier male > 35 kg",
            "Sanglier femelle > 35 kg",
            "Sanglier max 35 kg",
            "Sanglier indéterminé",
          ].map((name) => (
            <AnimalItem
              content={content}
              name={name}
              key={name}
              errorList={errorList}
              updateContentBracelets={updateContentBracelets}
              updateContentNumber={updateContentNumber}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Autres</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {["Mouflon", "Renard", "Daim", "Raton laveur"].map((name) => (
            <AnimalItem
              content={content}
              name={name}
              key={name}
              errorList={errorList}
              updateContentBracelets={updateContentBracelets}
              updateContentNumber={updateContentNumber}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
